import React from 'react';
import LoginPMLogo from './LoginPMLogo';
import loginStyles from '../../../stylesheets/components/login.module.scss';
import layoutStyles from '../../../stylesheets/components/layout.module.scss';
import textStyles from '../../../stylesheets/components/text.module.scss';
import classNames from 'classnames';

const LoginFooter = () => {
  return (
    <div className={loginStyles.loginFooter}>
      <span className={classNames(textStyles.fs13, textStyles.colorSecondary)}>
        Powered by
      </span>
      <LoginPMLogo />
    </div>
  );
};

export default LoginFooter;
